import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';

import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { MainMenu } from './Components/MainMenu';
import { ContentArea } from './Components/ContentArea';

import { Home } from './Pages/Home';
import { Music } from './Pages/Music';
import { Software } from './Pages/Software';
import { Contact } from './Pages/Contact';
import { PathPlanningVisualization } from './Pages/ReactPathPlanning';

import PythonPathPlanning from './Pages/PythonPathPlanning.tsx';

import { HeartFrame } from './Pages/HeartFrame/HeartFrame';
import { BankingApp } from './Pages/BankingApp/BankingApp';

import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import 'bootstrap/dist/css/bootstrap.min.css';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <Router>
    <ScrollToTop />
    <MainMenu />
    <ContentArea>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/Music" element={<Music /> } />
        <Route path="/Software" element={<Software /> } />
        <Route path="/Contact" element={<Contact /> } />
        <Route path="/Software/PathPlanningVisualization" element={<PathPlanningVisualization /> } />
        <Route path="/Software/PythonPathPlanning" element={<PythonPathPlanning /> } />
        <Route path="/Software/HeartFrame" element={<HeartFrame /> } />
        <Route path="/Software/BankingApp" element={<BankingApp /> } />
      </Routes>
    </ContentArea>
  </Router>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
