import { Link } from "react-router-dom";

import "./BankingApp.css";

import home_page_image from "./home.png";
import home_logged_in_image from "./home_logged_in.png";
import login_page_image from "./login.png";
import email_confirmation_sent_image from "./email_confirmation_sent.png";
import email_confirmation from "./email_confirmation.png";
import large_transaction from "./large_transaction.png";
import register_non_matching_passwords from "./register_non_matching_passwords.png";
import demo_video from "./demo_video.webm";


export function BankingApp()
{
    return (
        <div className="content">
            <h1>Banking Web Application</h1>

            <p>
                For my Software Engineering class, I worked alongside 4 other students to develop a web application for a bank. The application allows users to create accounts, deposit and withdraw money, and transfer money between accounts. The application is written in C# using ASP.NET Core and Entity Framework Core. The application was briefly hosted on Azure, but is no longer available.
            </p>

            <p>
                The source code may be found on <a href="https://github.com/UMKC-CS451R/cs415r_f21_groupproject-fcbi">GitHub</a>.
            </p>

            <video id="banking_app_demo" autoPlay muted loop>
                <source src={demo_video} type="video/webm" />
            </video>
        </div>
    );
}