
import 'bootstrap/dist/css/bootstrap.min.css';

export function ContentArea(props)
{
  return (
    <div class="bs-example">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 bg-light text-center mt-4 pb-4 pt-4 content border border-black rounded rounded-sm-5">
                  {props.children}
                </div>
            </div>
        </div>
    </div>
  )
}