
import { Link } from "react-router-dom";
import path_planning_image from "../path-plan.png";
import python_path_planning_image from "./PythonPathPlanning.png"; 
import heart_frame_vid from "./HeartFrame/final_product.mp4";
import banking_app_pic from "./BankingApp/home.png";


import "bootstrap/dist/css/bootstrap.min.css";

export function Software() {
    return (
        <div className="container">
            <h1 className="display-4">Software</h1>
            <hr className="my-4" />

            <div className="row">
                <div className="col-md-4">
                    <Link to="/Software/BankingApp" className="btn btn-dark">
                        Link to Demo
                    </Link>
                    <br />
                    <br />

                    <h2 className="display-6">Bank Web Application</h2>

                    <div className="embed-responsive embed-responsive-16by9">
                        <img src={banking_app_pic} className="img-fluid" />
                    </div>
                    <br />

                    <ul className="list-group flush">
                        <li className="list-group-item">Agile dvelopment</li>
                        <li className="list-group-item"> C# and .NET Core </li>
                        <li className="list-group-item">Microsoft Identity core</li>
                        <li className="list-group-item">Entity Framework Core</li>
                        <li className="list-group-item">Bootstrap</li>
                        <li className="list-group-item">Azure</li>
                    </ul>
                </div>

                <div className="col-md-4">
                    <Link to="/Software/PathPlanningVisualization" className="btn btn-dark">
                        Link to Demo
                    </Link>
                    <br />
                    <br />
                    <h2 className="display-6">ReactJS Path Planning</h2>

                    <div className="embed-responsive embed-responsive-16by9">
                        <img id="path_plan_image" src={path_planning_image} className="img-fluid" />
                    </div>

                    <br />

                    <ul className="list-group">
                        <li className="list-group-item">Algorithm Visualization</li>
                        <li className="list-group-item">Dijkstra's, A*, RRT</li>
                        <li className="list-group-item">Client side path planning and rendering</li>
                        <li className="list-group-item">ReactJS</li>
                    </ul>
                </div>

                <div className="col-md-4">
                    <Link to="/Software/PythonPathPlanning" className="btn btn-dark">
                        Link to Demo
                    </Link>
                    <br />
                    <br />
                    <h2 className="display-6">Python Path Planning</h2>

                    <div className="embed-responsive embed-responsive-16by9">
                        <img id="path_plan_image" src={python_path_planning_image} className="img-fluid" />
                    </div>

                    <br />

                    <ul className="list-group">
                        <li className="list-group-item">Algorithm Visualization</li>
                        <li className="list-group-item">Dijkstra's, A*, RRT</li>
                        <li className="list-group-item">Server side path planning and rendering</li>
                        <li className="list-group-item">Python</li>
                        <li className="list-group-item">Flask</li>
                        <li className="list-group-item">Matplotlib</li>
                    </ul>
                </div>
            </div>
            <br />
            <div className="row">
                <hr className="my-4" />
                <div className="col-md-4">
                    <Link to="/Software/HeartFrame" className="btn btn-dark">
                        Link to Demo
                    </Link>
                    <br />
                    <br />
                    <h2 className="display-6">LED Photo Frame</h2>
                    <div className="embed-responsive embed-responsive-16by9">
                        <video id="heart_frame_vid" autoPlay muted loop className="embed-responsive-item" style={{ width: "100%", height: "100%" }}>
                            <source src={heart_frame_vid} type="video/mp4"></source>
                        </video>
                    </div>
                    <br />
                    <ul className="list-group">
                        <li className="list-group-item">Discrete ATMega328P microcontroller</li>
                        <li className="list-group-item">C Programming Language</li>
                        <li className="list-group-item">Custom designed PCB</li>
                        <li className="list-group-item">3d printed backing plate with grooves for LEDs</li>
                    </ul>
                </div>
            </div>
        </div>
    );
}