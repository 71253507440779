import { useRef, useState, useEffect } from "react";
import { StaticDrawUsage } from "three";
import "./Software.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./ReactPathPlanning.css";

class Node {
    constructor(x, y) {
        this.x = x;
        this.y = y;

        this.parent = null;
        this.cost = Infinity;

        this.valid = true;
        this.active = false;

        this.heuristic = 0;

        this.visited = false;

        this.start = false;
        this.end = false;
    }

    distance(other) {
        return Math.sqrt(Math.pow(this.x - other.x, 2) + Math.pow(this.y - other.y, 2));
    }
}

class Obstacle {
    constructor(x, y, radius) {
        this.x = x;
        this.y = y;
        this.radius = radius;
    }

    collidesWith(node) {
        const p1 = { x: node.x - 0.5 * 0.75, y: node.y - 0.5 * 0.75 };
        const p2 = { x: node.x + 0.5 * 0.75, y: node.y - 0.5 * 0.75 };
        const p3 = { x: node.x + 0.5 * 0.75, y: node.y + 0.5 * 0.75 };
        const p4 = { x: node.x - 0.5 * 0.75, y: node.y + 0.5 * 0.75 };

        const d1 = Math.sqrt(Math.pow(p1.x - this.x, 2) + Math.pow(p1.y - this.y, 2));
        const d2 = Math.sqrt(Math.pow(p2.x - this.x, 2) + Math.pow(p2.y - this.y, 2));
        const d3 = Math.sqrt(Math.pow(p3.x - this.x, 2) + Math.pow(p3.y - this.y, 2));
        const d4 = Math.sqrt(Math.pow(p4.x - this.x, 2) + Math.pow(p4.y - this.y, 2));

        if (d1 < this.radius || d2 < this.radius || d3 < this.radius || d4 < this.radius) {
            return true;
        }
        return false;
    }

}


class Grid {
    constructor(x_min, x_max, y_min, y_max, spacing) {
        this.x_min = x_min;
        this.x_max = x_max;

        this.y_min = y_min;
        this.y_max = y_max;

        this.spacing = spacing;

        this.width = x_max - x_min;
        this.height = y_max - y_min;

        this.nodes = {};
        this.obstacles = [];

        for (let x = x_min; x < x_max; x += spacing) {
            for (let y = y_min; y < y_max; y += spacing) {
                this.nodes[[x, y]] = new Node(x, y);
            }
        }

        this.setStart(this.nodes[[x_min, y_min]]);
        this.setEnd(this.nodes[[x_max - spacing, y_max - spacing]]);
    }

    setStart(node) {
        if (this.start) this.start.start = false;
        this.start = node;
        this.start.start = true;
    }

    setEnd(node) {
        if (this.end) this.end.end = false;
        this.end = node;
        this.end.end = true;
    }

    addObstacle(obstacle) {
        this.obstacles.push(obstacle);

        for (let node_pos of Object.keys(this.nodes)) {
            const node = this.nodes[node_pos];
            if (obstacle.collidesWith(node)) node.valid = false;
        }
    }

    addObstacles(obstacles) {
        for (let obstacle of obstacles) {
            this.addObstacle(obstacle);
        }
    }

    getNode(x, y) {
        if (this.nodes[[x, y]]) return this.nodes[[x, y]];

        x = Math.round(x / this.spacing) * this.spacing
        y = Math.round(y / this.spacing) * this.spacing

        if (x < this.x_min)
            x = this.x_min
        else if (x >= this.x_max)
            x = this.x_max - this.spacing

        if (y < this.y_min)
            y = this.y_min
        else if (y >= this.y_max)
            y = this.y_max - this.spacing

        if (this.nodes[[x, y]]) return this.nodes[[x, y]];
        else {
            console.log("ERROR: Node not found");
            console.log(x, y);
            return null;
        }

        return this.nodes[(x, y)]
    }
}

function AStar(grid) {
    return Dijkstras(grid, true);
}

function Dijkstras(grid, useHeuristic = false) {
    const animation = new Array();

    for (let node_pos of Object.keys(grid.nodes)) {
        const node = grid.nodes[node_pos];
        node.cost = Infinity;
        node.parent = null;
        node.active = false;
        node.visited = false;
    }
    const start = grid.start;
    const end = grid.end;

    start.cost = 0;

    let current_node = start;
    const unvisited_nodes = new Set(Object.values(grid.nodes));
    const visited_nodes = new Set();
    const seen = new Set([start]);

    while (unvisited_nodes.size > 0 && current_node != end) {

        unvisited_nodes.delete(current_node);
        visited_nodes.add(current_node);

        const nodeRef = current_node;

        animation.push(() => nodeRef.visited = true);

        for (let y = current_node.y - grid.spacing; y <= current_node.y + grid.spacing; y += grid.spacing) {
            for (let x = current_node.x - grid.spacing; x <= current_node.x + grid.spacing; x += grid.spacing) {
                const neighbor = grid.nodes[[x, y]];

                if (neighbor && neighbor.valid && neighbor != current_node && !visited_nodes.has(neighbor)) {
                    neighbor.heuristic = neighbor.distance(end);
                    seen.add(neighbor);

                    const cost = current_node.cost + current_node.distance(neighbor);

                    if (cost < neighbor.cost) {
                        neighbor.cost = cost;
                        neighbor.parent = current_node;
                    }
                }
            }
        }

        if (unvisited_nodes.size > 0) {
            seen.delete(current_node);
            if (seen.size == 0) {
                break;
            }

            let min_cost = Infinity;
            if (useHeuristic) {
                for (let node of seen) {
                    if (node.cost + node.heuristic < min_cost) {
                        min_cost = node.cost + node.heuristic;
                        current_node = node;
                    }
                }
            } else {
                for (let node of seen) {
                    if (node.cost < min_cost) {
                        min_cost = node.cost;
                        current_node = node;
                    }
                }
            }
        }
    }

    current_node = end;
    while (current_node) {
        const nodeRef = current_node;

        animation.push(() => nodeRef.active = true);
        current_node = current_node.parent;
    }

    return animation;
}

function RRT(grid) {
    const animation = new Array();

    for (let node_pos of Object.keys(grid.nodes)) {
        const node = grid.nodes[node_pos];
        node.cost = Infinity;
        node.parent = null;
        node.active = false;
        node.visited = false;
    }

    const all_nodes = Object.values(grid.nodes);

    grid.start.cost = 0;
    const visited_tree = new Set();
    visited_tree.add(grid.start);

    var iterations = 0;
    while (iterations < 10000) {
        iterations += 1

        const random_node = grid.getNode(Math.random() * grid.width + grid.x_min, Math.random() * grid.height + grid.y_min);

        if (!random_node.valid || visited_tree.has(random_node)) continue;


        var closest_node = null;
        var min_distance = Infinity;

        for (let node of visited_tree) {
            const distance = node.distance(random_node);
            if (distance < min_distance) {
                min_distance = distance;
                closest_node = node;
            }
        }

        const angle = Math.atan2(random_node.y - closest_node.y, random_node.x - closest_node.x);

        const new_x = closest_node.x + Math.cos(angle) * grid.spacing;
        const new_y = closest_node.y + Math.sin(angle) * grid.spacing;

        const new_node = grid.getNode(new_x, new_y);

        if (!new_node.valid || visited_tree.has(new_node)) continue;


        new_node.parent = closest_node;

        animation.push(() => new_node.visited = true);
        new_node.cost = closest_node.cost + closest_node.distance(new_node);
        visited_tree.add(new_node);

        if (new_node.distance(grid.end) < 2 * grid.spacing) {

            if (new_node != grid.end) {
                grid.end.parent = new_node;
                grid.end.cost = new_node.cost + new_node.distance(grid.end);
                break;
            }
        }


        if (new_node == grid.end) {
            break;
        }

    }

    var current_node = grid.end;
    while (current_node) {
        const node = current_node;
        animation.push(() => node.active = true);

        current_node = current_node.parent;
    }

    return animation;
}

export function PathPlanningVisualization() {
    const MODE_DRAW_OBSTACLES = 0;
    const MODE_SET_START = 1;
    const MODE_SET_END = 2;
    const MODE_RUN = 3;
    const MODE_SHOW_PATH = 4;

    const currentMode = useRef(MODE_DRAW_OBSTACLES);

    const [algorithm, setAlgorithm] = useState("Dijkstras");

    const canvas = useRef();
    const grid = useRef(new Grid(0, 10, 0, 10, 1));

    const mouseX = useRef(0);
    const mouseY = useRef(0);

    const [width, setWidth] = useState(10);
    const [height, setHeight] = useState(10);
    const [pathLength, setPathLength] = useState(Infinity);
    const animationSpeed = useRef(50);
    const redrawNeeded = useRef(true);

    const mouseDown = useRef(false);

    const animation = useRef(new Array());
    const animationFrame = useRef(0);

    function onMouseUpdate(e) {
        if (e.buttons != 0) {
            mouseDown.current = true;
        } else {
            mouseDown.current = false;
        }

        var rect = canvas.current.getBoundingClientRect();
        const scaleX = canvas.current.width / rect.width;
        const scaleY = canvas.current.height / rect.height;

        mouseX.current = (e.clientX - rect.left) * scaleX;  // scale mouse coordinates after they have
        mouseY.current = (e.clientY - rect.top) * scaleY;     // been adjusted to be relative to element

        redrawNeeded.current = true;
    }

    function onTouchUpdate(e) {
        if (e.touches.length == 0) {
            mouseDown.current = false;
            return;
        }

        mouseDown.current = true;
        const touch = e.touches[0];

        var rect = canvas.current.getBoundingClientRect();
        const scaleX = canvas.current.width / rect.width;
        const scaleY = canvas.current.height / rect.height;

        mouseX.current = (touch.clientX - rect.left) * scaleX;  // scale mouse coordinates after they have
        mouseY.current = (touch.clientY - rect.top) * scaleY;     // been adjusted to be relative to element

        redrawNeeded.current = true;
    }

    const animate = () => {
        if (animationFrame.current >= animation.current.length) return;

        animation.current[animationFrame.current]();
        animationFrame.current += 1;

        redrawNeeded.current = true;

        setTimeout(() => animate(), 1000.0 / (animationSpeed.current));
    }

    const draw = () => {
        if (!redrawNeeded.current) {
            requestAnimationFrame(draw);
            return;
        }

        const ctx = canvas.current.getContext('2d');

        const pixelWidth = canvas.current.width / grid.current.width;
        const pixelHeight = canvas.current.height / grid.current.height;

        ctx.clearRect(0, 0, canvas.current.width, canvas.current.height);

        var nodeRect = new Path2D();
        nodeRect.rect(0, 0, pixelWidth * 0.75, pixelHeight * 0.75);

        for (let node_pos of Object.keys(grid.current.nodes)) {
            const node = grid.current.nodes[node_pos];

            if (node.valid) {
                if (node.active) {
                    ctx.fillStyle = 'rgb(0, 150, 0)';
                } else if (node.visited) {
                    ctx.fillStyle = 'rgb(200, 200, 200)';
                } else {
                    ctx.fillStyle = 'rgb(100, 100, 100)';
                }
            } else {
                ctx.fillStyle = "rgb(150, 0, 0)";
            }

            if (node.start || node.end) ctx.fillStyle = "rgb(0, 0, 150)";

            ctx.translate(node.x * pixelWidth, node.y * pixelHeight);
            ctx.fill(nodeRect);
            ctx.translate(-node.x * pixelWidth, -node.y * pixelHeight);
        }

        if (true) {
            const x = Math.floor(mouseX.current / pixelWidth);
            const y = Math.floor(mouseY.current / pixelHeight);

            const node = grid.current.nodes[[x, y]];

            if (node) {
                if (node.start || node.end) {
                    ctx.fillStyle = "rgb(0, 0, 256)";
                } else if (node.active && currentMode.current == MODE_SHOW_PATH) {
                    ctx.fillStyle = "rgb(0, 256, 0)";
                } else if (!node.valid) {
                    ctx.fillStyle = "rgb(256, 0, 0)";
                } else {
                    ctx.fillStyle = "rgb(200, 200, 200)";
                }

                if (mouseDown.current) {
                    if (currentMode.current == MODE_DRAW_OBSTACLES) {
                        node.valid = false;
                    } else if (currentMode.current == MODE_SET_START) {
                        grid.current.setStart(node);

                        currentMode.current = MODE_RUN;
                    } else if (currentMode.current == MODE_SET_END) {
                        grid.current.setEnd(node);

                        currentMode.current = MODE_RUN;
                    }
                }

                ctx.translate(node.x * pixelWidth, node.y * pixelHeight);
                ctx.fill(nodeRect);
                ctx.translate(-node.x * pixelWidth, -node.y * pixelHeight);
            }

            redrawNeeded.current = false;
            requestAnimationFrame(draw);
        }
    }

    useEffect(() => {
        const ctx = canvas.current.getContext("2d");
        draw();

        return () => {
        }
    });

    const findPath = () => {
        currentMode.current = MODE_SHOW_PATH;

        if (algorithm == "Dijkstras") {
            animation.current = Dijkstras(grid.current);
        } else if (algorithm == "A*") {
            animation.current = AStar(grid.current);
        } else if (algorithm == "RRT") {
            animation.current = RRT(grid.current);
        }

        animationFrame.current = 0;
        animate();

        setPathLength(grid.current.end.cost);
    }

    const resizeGrid = (w, h) => {
        grid.current = new Grid(0, w, 0, h, 1);
        setWidth(w);
        setHeight(h);
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-12">
                    <h1 className="display-4">Path Planning Visualization</h1>
                </div>
            </div>
            <div className="row"
                                                onMouseMove={onMouseUpdate}
                                                onMouseDown={onMouseUpdate}
                                                onMouseUp={onMouseUpdate}
            
                                                onTouchMove={onTouchUpdate}
                                                onTouchStart={onTouchUpdate}
                                                onTouchEnd={onTouchUpdate}
                                                onTouchCancel={onTouchUpdate}>
                <div className="col-4">
                    <div id="dijsktras">
                        <div id="Description" className="card p-3"> {/* use the card class to style the description as a card */}
                            <p className="card-text">
                                This is a simple visualization of 3 path planning algorithms: Dijkstras, A*, and RRT. It is implemented in React and uses renders with simple JavaScript in an HTML5 canvas.
                            </p>
                            <p className="card-text">
                                Press the "Draw Obstacles" button click and drag to create impassable regions in the grid. Press the "Set Start" button and click on a node to set the start position. Press the "Set End" button and click on a node to set the end position. Press the "Find Path" button to run the selected algorithm. The "Reset" button will clear the grid.
                            </p>
                        </div>
                        <label>Path Length: {pathLength}</label>
                        <br />
                        <br />
                        <div id="drop-downs" className="form-group">
                            <label htmlFor="algorithm">Algorithm:</label>

                            <select className="form-control"
                                id="algorithm" value={algorithm} onChange={(e) => setAlgorithm(e.target.value)}>
                                <option value="Dijkstras">Dijkstras</option>
                                <option value="A*">A*</option>
                                <option value="RRT">RRT</option>
                            </select>
                        </div>
                        <div id="sliders" className="form-group">
                            <label htmlFor="width">Grid Width: {width}</label>
                            <input className="form-control"
                                type="range" min={1} max={50} id="width" name="width"
                                value={width}
                                onChange={(e) => resizeGrid(parseInt(e.target.value), height)} />


                            <label htmlFor="height">Grid Height: {height}</label>
                            <input className="form-control"
                                type="range" min={1} max={50} id="height" name="height"
                                value={height}
                                onChange={(e) => resizeGrid(width, parseInt(e.target.value))} />
                        </div>
                        <div className="form-group">
                            <label htmlFor="speed">Animation Speed</label>
                            <input className="form-control" type="range" min={1} max={100} id="speed" name="speed"
                                defaultValue={animationSpeed.current}
                                onChange={(e) => animationSpeed.current = parseInt(e.target.value)} />

                        </div>
                    </div>
                    <br />
                </div>
                <div className="col-8">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-12 align-self-center">
                                <canvas id="grid" ref={canvas}
                                    style={{ width: 500, height: 500, touchAction: "none"}}
                                    >
                                    
                                </canvas>
                                <br />
                                <div id="buttons" className="btn-group">
                        <button className="btn btn-primary" onClick={() => currentMode.current = MODE_DRAW_OBSTACLES}>Draw Obstacles</button>
                        <button className="btn btn-primary" onClick={() => currentMode.current = MODE_SET_START}>Set Start</button>
                        <button className="btn btn-primary" onClick={() => currentMode.current = MODE_SET_END}>Set End</button>
                        <button className="btn btn-primary" onClick={() => findPath()}>Find Path</button>
                        <button className="btn btn-primary" onClick={() => { grid.current = new Grid(0, width, 0, height, 1); }}>Reset</button>
                    </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}