import "bootstrap/dist/css/bootstrap.min.css";
import "../Components/SocialMediaIcons"
import { SocialMediaIcons } from "../Components/SocialMediaIcons";

export function Contact() {
    return (
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-md-6 text-center">
                    <h1 className="display-4 text-left">Contact</h1>
                    <div class="container">
                        <div class="row py-2 my-3">
                            <div class="col-sm-3 text-right">
                                <h5 class="font-weight-bold label-color">E-Mail:</h5>
                            </div>
                            <div class="col-sm-9 px-2"><a className="link-dark text-left" href="mailto:edward@edwardkrulewich.com">edward@edwardkrulewich.com</a></div>
                        </div>
                        <div class="row py-2 my-3">
                            <div class="col-sm-3 text-right">
                                <h5 class="font-weight-bold label-color text-left">Phone:</h5>
                            </div>
                            <div class="col-sm-9 px-2"><a className="link-dark text-left" href="tel:816-206-8121">816-206-8121</a></div>
                        </div>

                        
                    </div>
                    <SocialMediaIcons />
                </div>
            </div>
        </div>
    );
}
