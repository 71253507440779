import { Link } from "react-router-dom";
import heart_frame_vid from "./final_product.mp4";
import frame_back_pic from "./frame_back_view.jpg";

import print_vid from "./3d_print.mp4";
import pcb_3d_print_mount from "./pcb_flush_3d_print.mp4";

import pcb_pic_front from "./pcb_soldered_front.jpg";
import pcb_pic_back from "./pcb_soldered_back.jpg";

import early_design from "./early_design.mp4";
import perfboard_pic from "./perfboard.jpg";



import "../Software.css";
import "./HeartFrame.css";

export function HeartFrame()
{
    return (
        <div className="content">
            <h1>ATMega328P Heart LED Photo Frame</h1>
            <video id="heart_frame_vid" autoPlay muted loop>
                <source src={heart_frame_vid} type="video/mp4" >
                </source>
            </video>
            
            <br />
            <br />

            <p>While taking a class on computer architecture I became interested in digital circuits and microcontrollers. In order to apply some of what I was learning to a real word project, I made this LED back-lit heart photoframe. The completed build is shown in the video above. </p>
            <br/>
            
            <p>
                The design uses a single ATMega328P microcontroller within a custom PCB I designed myself and had printed.
            </p>

            <img src={pcb_pic_front} className="media"/> <br/> <br/>
            <img src={pcb_pic_back} className="media"/> <br/>
            <br/>

            <p>I used a 3d printer to create a backing board for the photo to rest on. The board has 16 holes placed precisely for the LEDs to slot through.</p>

            <video id="print_vid" autoPlay muted loop>
                <source src={print_vid} type="video/mp4" >
                </source>
            </video>

            <br />
            <br />

            {/* <img src={print_image} className="media"/> <br/> */}
            <video id="print_vid" autoPlay muted loop>
                <source src={pcb_3d_print_mount} type="video/mp4" >
                </source>
            </video>

            <br/>
            <br/>


            <p> I then purcahsed 2 photo frames and mounted the second one backwards, against the first, with the PCB in between.</p>

            <img src={frame_back_pic} className="media" />

            <br />
            <br />

            <hr />
            <h2>Previous Design</h2>

            <video id="early_design" className="media" autoPlay muted loop>
                <source src={early_design} type="video/mp4" >
                </source>
            </video>

            <br />
            <br />

            <p>
                The design when through a number of iterations, and it took be a lot of trial and error to get to the design above. Above you can see my first rough design.
            </p>

            <p> This design was created using perboard rather than a PCB. Rather than the 3d printed backboard, this design featured a 4" x 6" block of wood, and required me to manually drill the holes. The end result is clunky and it was extremely difficult to get the holes correctly placed, taking me many attempts and wasting many blocks of wood. Using 3d printing in the new design made things a lot easier (and compact)</p>

            <img src={perfboard_pic} className="media" />

            <br />
            <br />


        </div>
    );
}