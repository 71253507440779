
import { NavLink, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

function MenuItem(props) {
  let location = useLocation().pathname.toLowerCase();
  const active = location === props.to.toLowerCase();

  if (active) {
    return (
      <li className="nav-item active">
        <NavLink to={props.to} className="nav-link">
          {props.children}
        </NavLink>
      </li>
    );
  } else {
    return (
      <li className="nav-item">
        <NavLink to={props.to} className="nav-link">
          {props.children}
        </NavLink>
      </li>
    );
  }
}

export function MainMenu(props) {
  return (
    <nav className="navbar navbar-expand-lg navbar-dark bg-dark mb-4">
      <button
        className="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarNav">
        <ul className="navbar-nav mx-auto">
          <MenuItem to="/">Home</MenuItem>
          <MenuItem to="/Software">Projects</MenuItem>
          {/* <MenuItem to="/Music">Music</MenuItem> */}
          <MenuItem to="/Contact">Contact</MenuItem>
        </ul>
      </div>
    </nav>
  );
}
