import {SocialMediaIcons} from "../Components/SocialMediaIcons";
import self_photo from "../photo.jpg";
import "./Home.css";

import 'bootstrap/dist/css/bootstrap.min.css';


function BioPhoto()
{
  return (
    <div className="bioPhoto">
      <img src={self_photo} className="img-fluid" />
      <hr/>
    </div>
  );
}

export function Home(props)
{
    return (
        <div className="content">
            <BioPhoto />
            <h2>Edward Krulewich</h2>
            Software Developer<br/><br/>
            <SocialMediaIcons />
          <div className="bioText">
            <p>
              I'm a software developer completing a MS in Computer Science at the University of Missouri Kansas City.
            </p>
          </div>
      </div>
    );
}